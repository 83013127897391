import { createTheme } from '@mui/material';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

import h1 from './typography/h1';
import h2 from './typography/h2';
import h3 from './typography/h3';
import h4 from './typography/h4';
import h5 from './typography/h5';
import subtitle1 from './typography/subtitle1';
import body1 from './typography/body1';
import body2 from './typography/body2';
import bodyL from './typography/bodyL';
import bodyLE from './typography/bodyLE';
import bodyM from './typography/bodyM';
import bodyME from './typography/bodyME';
import bodyS from './typography/bodyS';
import bodySE from './typography/bodySE';
import bodyXS from './typography/bodyXS';
import button from './typography/button';
import buttonS from './typography/buttonS';
import caption from './typography/caption';
import strikethroughS from './typography/strikethroughS';
import strikethroughM from './typography/strikethroughM';
import colors from './palette/colors';
import underlinedBodyL from './typography/underlinedBodyL';
import underlinedBodyM from './typography/underlinedBodyM';
import underlinedBodyS from './typography/underlinedBodyS';
import underlinedBodyXS from './typography/underlinedBodyXS';
import underlinedButton from './typography/underlinedButton';

interface ColorScale {
  '01': string;
  '03': string;
  '04': string;
  '05': string;
  '06': string;
  '07': string;
  '08': string;
  '09': string;
}
declare module '@mui/material/styles' {
  // WARNING: This augments the palette type with props to hold the greyscale and ui states.
  interface Palette {
    marigold: string;
    cyan: string;
    teal: string;
    navy: string;
    greyscale: {
      white: string;
      black: string;
      lightGrey: string;
      mediumGrey: string;
      backgroundGrey: string;
    } & ColorScale;
    gradients: {
      background: string;
      blueSky: string;
    };
    transparent: {
      background: string;
      blueSky: string;
      inverseBlueSky: string;
      landingPage1: string;
      landingPage2: string;
      landingPage3: string;
      blackBlur: string;
      marigold6: string;
      marigold12: string;
      marigold24: string;
      cyan6: string;
      cyan12: string;
      cyan24: string;
      white6: string;
      white12: string;
      white24: string;
    };
  }

  // WARNING: This augments the palette options with props to hold the greyscale and ui states.
  interface PaletteOptions {
    marigold: string;
    cyan: string;
    teal: string;
    navy: string;
    greyscale: {
      white: string;
      black: string;
      lightGrey: string;
      mediumGrey: string;
      backgroundGrey: string;
    } & ColorScale;
    gradients: {
      background: string;
      blueSky: string;
    };
    transparent: {
      background: string;
      blueSky: string;
      inverseBlueSky: string;
      landingPage1: string;
      landingPage2: string;
      landingPage3: string;
      blackBlur: string;
      marigold6: string;
      marigold12: string;
      marigold24: string;
      cyan6: string;
      cyan12: string;
      cyan24: string;
      white6: string;
      white12: string;
      white24: string;
    };
  }
  interface TypographyVariants {
    bodyL: TypographyStyleOptions;
    bodyLE: TypographyStyleOptions;
    bodyM: TypographyStyleOptions;
    bodyME: TypographyStyleOptions;
    bodyS: TypographyStyleOptions;
    bodySE: TypographyStyleOptions;
    bodyXS: TypographyStyleOptions;
    buttonS: TypographyStyleOptions;
    strikethroughS: TypographyStyleOptions;
    strikethroughM: TypographyStyleOptions;
    underlinedBodyL: TypographyStyleOptions;
    underlinedBodyM: TypographyStyleOptions;
    underlinedBodyS: TypographyStyleOptions;
    underlinedBodyXS: TypographyStyleOptions;
    underlinedButton: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyL?: TypographyStyleOptions;
    bodyLE?: TypographyStyleOptions;
    bodyM?: TypographyStyleOptions;
    bodyME?: TypographyStyleOptions;
    bodyS?: TypographyStyleOptions;
    bodySE?: TypographyStyleOptions;
    bodyXS?: TypographyStyleOptions;
    buttonS?: TypographyStyleOptions;
    strikethroughS?: TypographyStyleOptions;
    strikethroughM?: TypographyStyleOptions;
    underlinedBodyL?: TypographyStyleOptions;
    underlinedBodyM?: TypographyStyleOptions;
    underlinedBodyS?: TypographyStyleOptions;
    underlinedBodyXS?: TypographyStyleOptions;
    underlinedButton?: TypographyStyleOptions;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyL: true;
    bodyLE: true;
    bodyM: true;
    bodyME: true;
    bodyS: true;
    bodySE: true;
    bodyXS: true;
    buttonS: true;
    strikethroughS: true;
    strikethroughM: true;
    underlinedBodyL: true;
    underlinedBodyM: true;
    underlinedBodyS: true;
    underlinedBodyXS: true;
    underlinedButton: true;
  }
}

const basetheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 600, md: 905, lg: 1240, xl: 1440 },
  },
  typography: {
    fontFamily: ['Roboto Mono'].join(','),
    h1,
    h2,
    h3,
    h4,
    h5,
    subtitle1,
    body1,
    body2,
    bodyL,
    bodyLE,
    bodyM,
    bodyME,
    bodyS,
    bodySE,
    bodyXS,
    buttonS,
    button,
    strikethroughS,
    strikethroughM,
    underlinedBodyL,
    underlinedBodyM,
    underlinedBodyS,
    underlinedBodyXS,
    underlinedButton,
    caption,
  },
  palette: colors,
});

const theme = basetheme;
export default theme;
