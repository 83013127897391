import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const h1: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontWeight: 'bold',
  fontSize: '48px',
  lineHeight: '64px',
  color: colors.greyscale.white,
};
export default h1;
