/**
 * formats a number to a currency string
 */
const formatCurrency = (value?: number, digits = 0) => {
  if (value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digits,
    }).format(value);
  }
  return '';
};

export default formatCurrency;
