import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const subtitle1: TypographyStyleOptions = {
  fontFamily: 'Roboto Mono, sans-serif',
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '28px',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  color: colors.greyscale.white,
};

export default subtitle1;
