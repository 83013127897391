import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const h2: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontWeight: 'bold',
  fontSize: '32px',
  lineHeight: '40px',
  color: colors.greyscale.white,
};

export default h2;
