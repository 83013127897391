import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const button: TypographyStyleOptions = {
  fontFamily: 'Roboto Mono, sans-serif',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  color: colors.greyscale.white,
};

export default button;
