import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const underlineBodyS: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '20px', // identical to box height, or 143%
  textDecorationLine: 'underline',
  color: colors.greyscale.white,
};

export default underlineBodyS;
