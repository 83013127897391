import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const h4: TypographyStyleOptions = {
  fontFamily: ' Roboto Mono, sans-serif',
  fontWeight: '500',
  fontSize: '18px',
  lineHeight: '28px',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  color: colors.greyscale.white,
};

export default h4;
