import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const bodyL: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontWeight: '300',
  fontSize: '18px',
  lineHeight: '28px',
  color: colors.greyscale.white,
};

export default bodyL;
