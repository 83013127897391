import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const bodySE: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '20px',
  color: colors.greyscale.white,
};
export default bodySE;
