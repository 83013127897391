import { useReducer, useEffect, Dispatch } from 'react';
import { createContainer } from 'react-tracked';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { RichTextContent } from '@graphcms/rich-text-types';
import { captureMessage, Severity } from '@sentry/gatsby';
import { ImageProps } from './Image';
import {
  LAPTOP_SKU,
  LAPTOP_NAME,
  LAPTOP_PURCHASE_LIMIT_PER_TRANSACTION,
} from '../settings';
import formatCurrency from '../utils/formatCurrency';
import { validateLocalStorageData } from '../utils/localStorageSchema';

export interface HPProfile {
  id?: string;
  meta?: {
    resourceType?: string;
    created?: string;
    lastModified?: string;
    version?: string;
    location?: string;
  };
  addresses?: {
    addressBookAlias?: string;
    countryCodeV2?: string;
    district?: string;
    fullName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    zip?: string;
    phone?: string;
    primary?: boolean;
    province?: string;
  }[];
  schemas?: string[];
  countryResidence?: string;
  emails?: {
    primary?: boolean;
    type?: string;
    value?: string;
    verified?: boolean;
    accountRecovery?: boolean;
  }[];
  enabled?: boolean;
  extendedMeta?: {
    createdByClient?: string;
    lastModifiedByClient?: string;
    lastModifiedByUser?: string;
  };
  legalZone?: string;
  locale?: string;
  name?: {
    givenName?: string;
    middleName?: string;
    familyName?: string;
  };
  phoneNumbers?: [
    {
      accountRecovery?: boolean;
      number?: string;
      primary?: boolean;
      type?: string;
      verified?: boolean;
      id?: string;
    },
  ];
  type?: string;
  userName?: string;
  identityProvider?: string;
}

export interface Product {
  id: string;
  storefrontId: string;
  sku: string;
  name: string;
  price: number;
  stock: number;
  handle?: string;
  cartName?: string;
  image?: ImageProps['image'];
}
export interface ProductInCart extends Product {
  quantity: number;
}
export interface CartSummary {
  zip: string;
  promo: string;
}
export interface User {
  id: string;
  profile?: HPProfile;
}

export interface LinkObj {
  id: string;
  url: string;
  name: string;
  purchasedOnly: null | boolean;
  userOnly: null | boolean;
}

export const mastheadVariantList = [
  'Title_Subtitle_Image_Three_Text_Blocks',
  'Title_Image_Two_Text_Blocks',
  'Title_Image_Subtitle_Two_Text_Blocks',
  'Title_and_Text',
  'Image_First',
  'Title_First',
] as const;

export type MastheadVariants = typeof mastheadVariantList[number];

export const appActionList = ['openLink', 'login', 'addToCart'];

/**
 * Application actions as defined in GraphCMS. These tell CTA button in the landing pages
 * what to do when clicked.
 */
export type AppAction = typeof appActionList[number];

export interface State {
  purchased: Product[];
  products?: Product[];
  cart: ProductInCart[];
  checkoutId?: string;
  cartSummary: CartSummary;
  user?: User;
  currentPage: string;
}

export interface ColorPickerData {
  hex: string;
  rgba: {
    a: number;
    b: number;
    g: number;
    r: number;
  };
}

export interface GraphCmsRichText {
  raw: RichTextContent;
}

export interface GraphCmsHtml {
  html: 'string';
}

export interface GatsbyImageDataParent {
  gatsbyImageData: IGatsbyImageData;
}

// IFaq due to naming collision.
export interface IFaq {
  id: string;
  question: string;
  category?: string;
  answer: GraphCmsHtml;
}

export interface ISpec {
  id: string;
  specificationName: string;
  specificationDescription: GraphCmsHtml;
}

export type Action =
  | { type: 'PURCHASED_ADD'; productIds: string[] }
  | { type: 'PURCHASED_REMOVE'; productId: string }
  | { type: 'CHECKOUT_SET_ID'; checkoutId: string }
  | { type: 'CART_RESET' }
  | { type: 'CART_ADD'; product: ProductInCart }
  | { type: 'CART_UPDATE_QUANTITY'; product: ProductInCart }
  | { type: 'CART_REMOVE'; product: ProductInCart }
  | { type: 'CART_UPDATE_SUMMARY'; cartSummary: CartSummary }
  | { type: 'USER_LOGIN'; user: User }
  | { type: 'USER_LOGOUT' }
  | { type: 'PRODUCTS_ADD'; products: Product[] }
  | { type: 'PAGE_SET_CURRENT'; currentPage: string }
  | { type: 'UPDATE_USER'; user: User };

const storageKey = 'hp-xy-gatsby';
const storageIdKey = 'hp-id-key';

const INITIAL_PAGE =
  typeof window !== 'undefined' ? window.location.pathname : '/';

// If you change the shape of initialState be sure to make the corresponding
// changes to the schema in localStorageSchema.ts or you'll have a bad day.
const initialState: State = {
  purchased: [],
  cart: [],
  checkoutId: undefined,
  cartSummary: {
    zip: '',
    promo: '',
  },
  products: undefined,
  user: undefined,
  currentPage: INITIAL_PAGE,
};

/**
 * Get product by sku.
 * @param sku string
 * @param products Array of products.
 * @returns Product or undefined.
 */
export const getProductBySku = (
  sku: string,
  products: Product[],
): Product | undefined => products?.find((product) => product?.sku === sku);

/**
 * Insert product prices in a given string using their SKU.
 * @param str Original string
 * @param products Array of products.
 * @returns String with actual prices.
 */
export const insertProductPrices = (
  str: string,
  products: Product[],
): string => {
  let updatedStr = str;
  products?.forEach((product) => {
    updatedStr = updatedStr.replace(
      `{{${product.sku}.price}}`,
      formatCurrency(product.price),
    );
  });
  return updatedStr;
};

export const getLaptop = (products?: Product[]): Product | undefined =>
  products?.find(
    (product) => product.sku === LAPTOP_SKU ?? product.name === LAPTOP_NAME,
  );

const validateLaptopQuantity = (state: State, cart: ProductInCart[]) => {
  const laptop = getLaptop(cart) as ProductInCart;
  if (laptop && laptop.quantity > LAPTOP_PURCHASE_LIMIT_PER_TRANSACTION) {
    laptop.quantity = LAPTOP_PURCHASE_LIMIT_PER_TRANSACTION;
  }
};

const deepRemoveTypename = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (key === '__typename') {
      // eslint-disable-next-line no-underscore-dangle, no-param-reassign
      delete obj.__typename;
    } else if (obj[key] instanceof Object) {
      deepRemoveTypename(obj[key]);
    }
  });
};

const initialize = () => {
  let state: State = initialState;
  try {
    if (typeof Storage !== 'undefined') {
      const storedState = localStorage?.getItem(storageKey);
      if (storedState) {
        const parsedState = JSON.parse(storedState);
        const [isValid, validatedState, errors] =
          validateLocalStorageData(parsedState);
        if (isValid) {
          state = {
            ...state,
            ...validatedState,
          };
        } else {
          captureMessage(`Local storage invalid: ${JSON.stringify(errors)}`, {
            level: Severity.Warning,
            extra: {
              storedState,
              validatedState: JSON.stringify(validatedState),
            },
          });
        }
        // Set localStorage to validated object whether it's valid or not. It'll do rewrites on valid schema
        // as well as invalid ones.
        localStorage?.setItem(storageKey, JSON.stringify(validatedState));
      }
    }
  } catch (e) {
    // ignore
  }
  return state;
};

const reducer = (state: State, action: Action): State => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
  }

  switch (action.type) {
    case 'PURCHASED_ADD':
      return (() => {
        const purchased = [...state.purchased];

        action.productIds.forEach((purchasedProductId) => {
          const haveProduct = purchased.some(
            (product) => product.id === purchasedProductId,
          );

          // only add device if it's not already added
          if (!haveProduct) {
            const currentProduct = state.products?.find(
              (product) => product.id === purchasedProductId,
            );
            if (currentProduct) {
              purchased.push(currentProduct);
            }
          }
        });

        return { ...state, purchased };
      })();

    case 'PURCHASED_REMOVE':
      return (() => {
        const purchased = state.purchased.filter(
          (product) => product.id !== action.productId,
        );
        return { ...state, purchased };
      })();

    case 'CHECKOUT_SET_ID':
      return (() => ({
        ...state,
        checkoutId: action.checkoutId,
      }))();

    case 'CART_RESET':
      return (() => ({
        ...state,
        checkoutId: undefined,
        cart: [],
      }))();

    case 'CART_ADD':
      return (() => {
        const cart = [...state.cart];
        const { checkoutId } = state;
        const productIndex = cart.findIndex(
          (product) => product.id === action.product.id,
        );
        const validIndex = productIndex >= 0;

        // If item is in cart, update quantity
        // otherwise, add new item to cart
        if (validIndex) {
          cart[productIndex] = {
            ...cart[productIndex],
            quantity: cart[productIndex].quantity + action.product.quantity,
          };
        } else {
          cart.push(action.product);
        }

        validateLaptopQuantity(state, cart);

        /**
         * UDL Analytics
         */
        window.dataLayer?.push({
          event: 'e_addToCart',
          cartID: checkoutId,
          ecommerce: {
            currencyCode: 'USD',
            add: {
              products: [
                {
                  name: action.product.name,
                  id: action.product.sku,
                  price: formatCurrency(action.product.price),
                  brand: 'HP DEV ONE',
                  quantity: validIndex
                    ? cart[productIndex].quantity
                    : action.product.quantity,
                },
              ],
            },
          },
        });

        return { ...state, cart };
      })();

    case 'CART_UPDATE_QUANTITY':
      return (() => {
        const cart = [...state.cart];
        const productIndex = cart.findIndex(
          (product) => product.id === action.product.id,
        );

        // If item is in cart, update quantity
        if (productIndex > -1) {
          cart[productIndex] = {
            ...cart[productIndex],
            quantity: action.product.quantity,
          };
        }
        validateLaptopQuantity(state, cart);

        return { ...state, cart };
      })();

    case 'CART_REMOVE':
      return (() => {
        const cart = state.cart.filter(
          (product) => product.id !== action.product.id,
        );

        /**
         * UDL Analytics
         */
        window.dataLayer?.push({
          event: 'e_removeFromCart',
          ecommerce: {
            currencyCode: 'USD',
            add: {
              products: [
                {
                  name: action.product.name,
                  id: action.product.sku,
                  price: formatCurrency(action.product.price),
                  brand: 'HP DEV ONE',
                  quantity: action.product.quantity,
                },
              ],
            },
          },
        });

        return { ...state, cart };
      })();

    case 'CART_UPDATE_SUMMARY':
      return (() => {
        const cartSummary = {
          ...state.cartSummary,
          ...action.cartSummary,
        };
        return { ...state, cartSummary };
      })();

    case 'PAGE_SET_CURRENT':
      return (() => ({
        ...state,
        currentPage: action.currentPage,
      }))();

    case 'USER_LOGIN':
      return (() => ({
        ...state,
        user: action.user,
      }))();

    case 'USER_LOGOUT': {
      // Removes tokens stored in localStorage and clears state
      // in app and localStorage.
      if (typeof Storage !== 'undefined') {
        localStorage?.removeItem(storageIdKey);
      }
      return (() => ({
        ...state,
        purchased: [],
        user: undefined,
      }))();
    }

    case 'PRODUCTS_ADD':
      return (() => ({
        ...state,
        products: action.products,
      }))();

    case 'UPDATE_USER':
      return (() => {
        const user = JSON.parse(
          JSON.stringify({
            ...state.user,
            ...action.user,
          }),
        );
        deepRemoveTypename(user);
        if (!action.user) {
          return { ...state, user: undefined };
        }
        return { ...state, user };
      })();

    default:
      return state;
  }
};

const useValue = (): readonly [State, Dispatch<Action>] => {
  const [state, dispatch] = useReducer(reducer, null, initialize);
  useEffect(() => {
    if (typeof Storage !== 'undefined') {
      localStorage?.setItem(storageKey, JSON.stringify(state));
    }
  }, [state]);
  return [state, dispatch];
};

export const {
  Provider: GlobalStateProvider,
  useTracked: useGlobalState,
  useUpdate: useDispatch,
} = createContainer(useValue);
