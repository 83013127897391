import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const underlinedBodyM: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '24px', // identical to box height, or 150%
  textDecorationLine: 'underline',
  color: colors.greyscale.white,
};

export default underlinedBodyM;
