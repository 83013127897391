import Ajv from 'ajv';

const ajv = new Ajv({
  removeAdditional: true,
  useDefaults: true,
  coerceTypes: true,
});

// Schema matches User interface in GlobalState.ts.
const userSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    profile: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        meta: {
          type: 'object',
          properties: {
            resourceType: { type: 'string' },
            created: { type: 'string' },
            lastModified: { type: 'string' },
            version: { type: 'string' },
            location: { type: 'string' },
          },
        },
        addresses: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              addressBookAlias: { type: 'string' },
              countryCodeV2: { type: 'string' },
              district: { type: 'string' },
              fullName: { type: 'string' },
              address1: { type: 'string' },
              address2: { type: 'string' },
              city: { type: 'string' },
              zip: { type: 'string' },
              primary: { type: 'boolean' },
              province: { type: 'string' },
            },
          },
        },
        schemas: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        countryResidence: { type: 'string' },
        emails: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              primary: { type: 'boolean' },
              type: { type: 'string' },
              value: { type: 'string' },
              verified: { type: 'boolean' },
              accountRecovery: { type: 'boolean' },
            },
          },
        },
        enabled: { type: 'boolean' },
        extendedMeta: {
          type: 'object',
          properties: {
            createdByClient: { type: 'string' },
            lastModifiedByClient: { type: 'string' },
            lastModifiedByUser: { type: 'string' },
          },
        },
        legalZone: { type: 'string' },
        locale: { type: 'string' },
        name: {
          type: 'object',
          properties: {
            givenName: { type: 'string' },
            middleName: { type: 'string' },
            familyName: { type: 'string' },
          },
        },
        phoneNumbers: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              accountRecovery: { type: 'boolean' },
              number: { type: 'string' },
              primary: { type: 'boolean' },
              type: { type: 'string' },
              verified: { type: 'boolean' },
              id: { type: 'string' },
            },
          },
        },
        type: { type: 'string' },
        userName: { type: 'string' },
        identityProvider: { type: 'string' },
      },
      required: ['id'],
    },
  },
  required: ['id', 'profile'],
};

const imageSchema = {
  type: 'object',
  properties: {
    images: {
      type: 'object',
      properties: {
        sources: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              srcSet: { type: 'string' },
              sizes: { type: 'string' },
              type: { type: 'string' },
            },
          },
        },
        fallback: {
          type: 'object',
          properties: {
            src: { type: 'string' },
            srcSet: { type: 'string' },
            sizes: { type: 'string' },
          },
        },
      },
    },
    layout: { type: 'string' },
    width: { type: 'number' },
    height: { type: 'number' },
  },
};

const productSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    cartName: { type: 'string' },
    handle: { type: 'string' },
    image: imageSchema,
    name: { type: 'string' },
    price: { type: 'number' },
    sku: { type: 'string' },
    stock: { type: 'number' },
    storefrontId: { type: 'string' },
  },
  required: ['id', 'name', 'price', 'sku', 'stock', 'storefrontId'],
};

// This should match initialState in GlobalState.ts
const localStorageSchema = {
  type: 'object',
  properties: {
    purchased: {
      type: 'array',
      default: [],
      items: {
        ...productSchema,
      },
    },
    cart: {
      type: 'array',
      default: [],
      items: {
        ...productSchema,
      },
    },
    checkoutId: {
      type: 'string',
    },
    cartSummary: {
      type: 'object',
      properties: {
        zip: {
          type: 'string',
          default: '',
        },
        promo: {
          type: 'string',
          default: '',
        },
      },
    },
    products: {
      type: 'array',
      items: {
        ...productSchema,
      },
    },
    user: userSchema,
    currentPage: {
      type: 'string',
      default: typeof window !== 'undefined' ? window.location.pathname : '/',
    },
  },
  // Items with undefined default values in initialState are not considered required here.
  required: ['purchased', 'cart', 'cartSummary', 'currentPage'],
  additionalProperties: false,
};

/**
 * Validate data against supplied schema.
 * @param schema Schema to validate against.
 * @param data Data to validate.
 * @returns [boolean, data]
 */
const validateSchema = (schema: any, data: any) => {
  // Make a new copy of the object because ajv mutates the object.
  const validatorData = { ...data };
  const validate = ajv.compile(schema);
  const valid = validate(validatorData);
  return [valid, validatorData, validate.errors];
};

/**
 * Validate data against localStorage schema defined in this module.
 * @param data Data to validate
 * @returns [boolean, data]
 */
const validateLocalStorageData = (data: any) =>
  validateSchema(localStorageSchema, data);

export { validateSchema, validateLocalStorageData };
