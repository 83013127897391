import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const underlinedBodyL: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '18px',
  lineHeight: '28px', // identical to box height, or 156%
  textDecorationLine: 'underline',
  color: colors.greyscale.white,
};

export default underlinedBodyL;
