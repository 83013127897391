import { PaletteOptions } from '@mui/material/styles/createPalette';

const colors: PaletteOptions = {
  mode: 'dark',
  // mode: 'light', // TODO: Not sure if this is going to be active at some point
  marigold: '#FFAD00', // Also Alert color
  cyan: '#45C7FF',
  teal: '#6ACAD8',
  navy: '#142341',
  greyscale: {
    white: '#FFFFFF',
    '01': '#F4F4F4',
    '03': '#E0E0E0',
    '04': '#C6C6C6',
    '05': '#A8A8A8',
    '06': '#8D8D8D',
    '07': '#6F6F6F',
    '08': '#525252',
    '09': '#393939',
    lightGrey: '#B0B0B0',
    mediumGrey: '#7A7A7A',
    backgroundGrey: '#0E1520', // Modal background grey
    black: '#000000',
  },
  error: {
    main: '#F04E37',
  },
  warning: {
    main: '#FFCB05',
  },
  success: {
    main: '#60DF6A',
  },
  gradients: {
    background: 'linear-gradient(180deg, #06070A 0%, #1A355D 198.49%)',
    blueSky: 'linear-gradient(180deg, #1A355D -14.74%, #6ACAD8 115.05%)',
  },
  transparent: {
    background:
      'linear-gradient(180deg, rgba(6, 7, 10, 0.5) 0%, rgba(26, 53, 93, 0.5) 198.49%)',
    blueSky:
      'linear-gradient(180deg, rgba(26, 53, 93, 0.5) -14.74%, rgba(106, 202, 216, 0.5) 115.05%)',
    inverseBlueSky:
      'linear-gradient(180.12deg, rgba(106, 202, 216, 0.35) -7.74%, rgba(26, 53, 93, 0.35) 107.74%)',
    landingPage1:
      'linear-gradient(180.12deg, rgba(26, 53, 93, 0.35) -7.74%, rgba(26, 53, 93, 0) 107.74%)',
    landingPage2:
      'linear-gradient(180deg, rgba(106, 202, 216, 0.25) -25.22%, rgba(106, 202, 216, 0) 105.5%)',
    landingPage3:
      'linear-gradient(180.12deg, rgba(69, 199, 255, 0.35) -7.74%, rgba(69, 199, 255, 0) 107.74%)',
    blackBlur: 'rgba(0, 0, 0, 0.4)', // Transparent black 40%
    marigold6: 'rgba(255, 173, 0, 0.06)',
    marigold12: 'rgba(255, 173, 0, 0.12)',
    marigold24: 'rgba(255, 173, 0, 0.24)',
    cyan6: 'rgba(69, 199, 255, 0.06)',
    cyan12: 'rgba(69, 199, 255, 0.12)',
    cyan24: 'rgba(69, 199, 255, 0.24)',
    white6: 'rgba(255, 255, 255, 0.06)',
    white12: 'rgba(255, 255, 255, 0.12)',
    white24: 'rgba(255, 255, 255, 0.24)',
  },
};

export default colors;
