import React, { useState } from 'react';
import { ThemeProvider, GlobalStyles, CssBaseline } from '@mui/material';
import { globalHistory, Location } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';
import { GlobalStateProvider } from './GlobalState';
import UDLProvider from './hp-udl/UDLProvider';
import theme from '../theme';
import { CartContext } from '../context/CartContext';
import { GraphContext } from '../context/GraphContext';
import { SupportContext } from '../context/SupportContext';

const GLOBAL_STYLES = `
  html {
    background: black;
  }
  body {
    min-height: 100vh;
    width: 100vw;
    @media (max-width: 600px) {
      overflow-x: hidden;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  svg {
    path, rect, circle, polygon, line {
      shape-rendering: geometricPrecision;
    }
  }
  #onetrust-consent-sdk {
    font-family: 'HPSimplifiedLight', sans-serif;
  }
  .superscript {
    vertical-align: super;
  }
  .notranslate {
    display: none;
  }
`;

const AllProviders: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [myCart, setShowCart] = useState(false);
  const [content, setGraphContent] = useState(null);
  const [open, setOpen] = useState(false);
  return (
    <Location>
      {({ location = window.location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          <GlobalStateProvider>
            <GraphContext.Provider value={{ content, setGraphContent }}>
              <CartContext.Provider value={{ myCart, setShowCart }}>
                <SupportContext.Provider value={{ open, setOpen }}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyles styles={GLOBAL_STYLES} />
                    <UDLProvider location={location} />
                    {children}
                  </ThemeProvider>
                </SupportContext.Provider>
              </CartContext.Provider>
            </GraphContext.Provider>
          </GlobalStateProvider>
        </QueryParamProvider>
      )}
    </Location>
  );
};

export default AllProviders;
