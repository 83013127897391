import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const h3: TypographyStyleOptions = {
  fontFamily: 'Roboto Mono, sans-serif',
  fontWeight: '500',
  fontSize: '24px',
  lineHeight: '36px',
  textTransform: 'uppercase',
  color: colors.greyscale.white,
};

export default h3;
