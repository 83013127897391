exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-survey-tsx": () => import("./../../../src/pages/survey.tsx" /* webpackChunkName: "component---src-pages-survey-tsx" */),
  "component---src-pages-user-account-tsx": () => import("./../../../src/pages/user/account.tsx" /* webpackChunkName: "component---src-pages-user-account-tsx" */),
  "component---src-pages-user-dashboard-tsx": () => import("./../../../src/pages/user/dashboard.tsx" /* webpackChunkName: "component---src-pages-user-dashboard-tsx" */),
  "component---src-pages-user-support-tsx": () => import("./../../../src/pages/user/support.tsx" /* webpackChunkName: "component---src-pages-user-support-tsx" */),
  "component---src-templates-auth-tsx": () => import("./../../../src/templates/auth.tsx" /* webpackChunkName: "component---src-templates-auth-tsx" */),
  "component---src-templates-express-tsx": () => import("./../../../src/templates/express.tsx" /* webpackChunkName: "component---src-templates-express-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

