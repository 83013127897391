import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const body1: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.02em',
  color: colors.greyscale.white,
};

export default body1;
