import React, { useEffect } from 'react';
import { useGlobalState } from '../GlobalState';
import { PRODUCTION_HOST } from '../../settings';

declare global {
  interface DataLayer {
    event: string;
    loginStatus?: boolean;
    errorPage?: boolean;
    cartID?: string;
    ecommerce?: {
      currencyCode: string;
      add?: {
        products: {
          name: string;
          id: string;
          price: string;
          brand: string;
          category?: string;
          variant?: string;
          quantity: number;
        }[];
      };
      remove?: {
        products: {
          name: string;
          id: string;
          price: string;
          brand: string;
          category?: string;
          variant?: string;
          quantity: number;
        }[];
      };
      detail?: {
        actionField: { list: 'view cart' };
        products: {
          name: string;
          id: string;
          price: string;
          brand: string;
          category?: string;
          variant?: string;
          quantity: number;
        }[];
      };
    };
  }
  interface Window {
    dataLayer?: DataLayer[];
    awDataLayer?: DataLayer[];
    gtag?: (...args: any[]) => void;
    fbq?: (...args: any[]) => void;
    FMApi?: any;
  }
}

export const fbqReportConversion = (type: string, options?: any) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq('track', type, options);
  }
};

export const gtagReportConversion = (url?: Location, options?: any) => {
  if (typeof window !== 'undefined' && window.gtag) {
    const callback = () => {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      send_to: 'AW-10857820383/B8ogCN27vrUDEN_htLko',
      event_callback: callback,
      ...options,
    });
  }
  return false;
};

const UDLProvider: React.FC<{ location: Location }> = ({ location }) => {
  const [{ user }] = useGlobalState();
  const PRODUCTION_SITE_ONLY = location.host === PRODUCTION_HOST;

  useEffect(() => {
    if (typeof window !== 'undefined' && window.gtag && PRODUCTION_SITE_ONLY) {
      window.dataLayer = window.dataLayer || [];

      // UDL
      window.dataLayer?.push({
        event: 'e_pageView',
        loginStatus: !!user,
        errorPage: location.pathname === '/404',
      });

      // Google Ads
      /* eslint-disable func-names */
      /* eslint-disable prefer-rest-params */
      window.gtag = function () {
        // @ts-expect-error: arguments is required here
        window.dataLayer?.push(arguments);
      };

      window.gtag('js', new Date());
      window.gtag('config', 'AW-10857820383');
    }
  }, []);

  return null;
};

export default UDLProvider;
