export const HPID_USER_REDIRECT_URL = `
${process.env.GATSBY_HPID_LOGIN_URL}
&client_id=${process.env.GATSBY_HPID_CLIENT_ID}
&redirect_uri=${process.env.GATSBY_HPID_REDIRECT_URL}
`;

export const HPID_LOGIN_URL = `
${process.env.GATSBY_HPID_LOGIN_URL}
&client_id=${process.env.GATSBY_HPID_CLIENT_ID}
&redirect_uri=${process.env.GATSBY_HPID_REDIRECT_URL}/auth
`;

export const HPID_CHECKOUT_URL = `
${process.env.GATSBY_HPID_LOGIN_URL}
&client_id=${process.env.GATSBY_HPID_CLIENT_ID}
&redirect_uri=${process.env.GATSBY_HPID_REDIRECT_URL}/checkout
`;

export const HPID_LOGOUT_URL = `
${process.env.GATSBY_HPID_LOGOUT_URL}
?post_logout_redirect_uri=${process.env.GATSBY_SITE_URL}
`;

export const SITE_URL = process.env.GATSBY_SITE_URL || '';

export const LAPTOP_PURCHASE_LIMIT_PER_TRANSACTION = 10;

export const HPID_SECURITY_TAB_URL =
  process.env.GATSBY_HPID_SECURITY_TAB_URL || '';

export const LAPTOP_SKU =
  process.env.GATSBY_LAPTOP_STOREFRONT_SKU || '68V48AA#ABA';

export const MOUSE_SKU =
  process.env.GATSBY_MOUSE_STOREFRONT_SKU || '1D0K8UT#ABA';

export const LAPTOP_NAME =
  process.env.GATSBY_LAPTOP_STOREFRONT_NAME || 'HP Dev One';

export const HPID_API_URL = process.env.GATSBY_HPID_API_URL || '';

export const HPID_PASSWORD_CHANGE_URL =
  process.env.GATSBY_HPID_PASSWORD_CHANGE_URL || '';

export const MIDDLEWARE_GRAPHQL_ENDPOINT =
  process.env.GATSBY_MIDDLEWARE_GRAPHQL_ENDPOINT || '';

export const SHOPIFY_GRAPHQL_ENDPOINT =
  process.env.GATSBY_SHOPIFY_API_URL || '';

export const SHOPIFY_GRAPHQL_ACCESS_TOKEN =
  process.env.GATSBY_SHOPIFY_ACCESS_TOKEN || '';

export const CONTACT_LIST_ID = Number(
  process.env.FRESHWORKS_CONTACT_LIST_ID || '0',
);

export const CANCEL_ORDER_LIMIT_IN_MINUTES = Number(
  process.env.CANCEL_ORDER_LIMIT_IN_MINUTES || '60',
);

export const ZIGPOLL_ACCOUNT_ID = process.env.GATSBY_ZIGPOLL_ACCOUNT_ID || '';

export const PRODUCTION_HOST = 'hpdevone.com';

export const { BUILD_TARGET } = process.env;

export const FRESHDESK_WIDGET =
  process.env.GATSBY_FRESHDESK_WiDGET || 'hpdevone.freshdesk.com';

export default {
  CONTACT_LIST_ID,
  HPID_API_URL,
  HPID_LOGIN_URL,
  HPID_CHECKOUT_URL,
  HPID_PASSWORD_CHANGE_URL,
  MIDDLEWARE_GRAPHQL_ENDPOINT,
  SHOPIFY_GRAPHQL_ENDPOINT,
  SHOPIFY_GRAPHQL_ACCESS_TOKEN,
  LAPTOP_SKU,
  LAPTOP_NAME,
  PRODUCTION_HOST,
  CANCEL_ORDER_LIMIT_IN_MINUTES,
  BUILD_TARGET,
};

export const enum RichTextElementType {
  TABLE = 'table',
  CLASS = 'class',
  PARAGRAPH = 'paragraph',
  TABLE_ROW = 'table_row',
  LINK = 'link',
}

export const enum RichTextClassName {
  FOOTNOTE = 'footnote',
}

export const enum PageID {
  HOME = 'home',
  SOFT_LAUNCH = 'soft-launch',
}
