import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const strikethroughS: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontWeight: '300', // design intent is the book weight, not normal/400 despite what figma says
  fontSize: '14px',
  lineHeight: '20px',
  textDecoration: 'line-through',
  color: colors.greyscale.white,
};

export default strikethroughS;
