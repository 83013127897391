import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const h5: TypographyStyleOptions = {
  fontFamily: 'Roboto Mono, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '20px' /* identical to box height, or 143% */,
  textAlign: 'center',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  color: colors.greyscale.white,
};

export default h5;
