import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const body2: TypographyStyleOptions = {
  fontFamily: 'HCo Gotham SSm, Gotham, sans-serif',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.025em',
  color: colors.greyscale.white,
};

export default body2;
