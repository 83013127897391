import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import colors from '../palette/colors';

const buttonS: TypographyStyleOptions = {
  fontFamily: 'Roboto Mono, sans-serif',
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '20px',
  letterSpacing: '1.2 px',
  textTransform: 'uppercase',
  color: colors.greyscale.white,
};

export default buttonS;
